import {
  Button,
  Dropdown,
  Icon,
  Label,
  Loader,
  Message,
  Responsive,
  Table,
} from 'semantic-ui-react';
import React, { Fragment, useState } from 'react';

import { DeclineModal } from '../components/Modals/DeclineModal';
import { Query } from 'react-apollo';
import { endOfDay } from 'date-fns/esm';
import { formatDateTime } from '../../../util/locale';
import { gql } from 'apollo-boost';
import { startOfDay } from 'date-fns';
import { formatTelephone } from 'util/telephone';

const TableContainer = ({ startDate, endDate, proViewId }) => {
  const [isLoading, setLoading] = useState(false);
  const [declineModal, setDeclineModal] = useState({
    open: false,
    solicitation: null,
    declineReason: '',
    touched: false,
  });

  return (
    <Query
      fetchPolicy="network-only"
      query={gql`
        query solicitation(
          $proViewId: Int!
          $startDate: timestamptz!
          $endDate: timestamptz!
          $jsonFilter: jsonb
        ) {
          solicitation(
            where: {
              _and: [
                { proView: { id: { _eq: $proViewId } } }
                { dateCreated: { _gte: $startDate } }
                { dateCreated: { _lte: $endDate } }
                {
                  _or: [
                    { configuration: { _isNull: true } }
                    {
                      _not: {
                        configuration: { _hasKey: "isRedirectedToBilik" }
                      }
                    }
                    {
                      configuration: {
                        _contains: { isRedirectedToBilik: false }
                      }
                    }
                  ]
                }
              ]
            }
            orderBy: { dateCreated: DESC }
          ) {
            id
            message
            dateCreated
            givenName
            familyName
            email
            telephone
            declinedBy
            declineReason
            uuid
            addressLocality
            proReview {
              id
            }
            configuration
          }
        }
      `}
      variables={{
        startDate: startOfDay(new Date(startDate)),
        endDate: endOfDay(new Date(endDate)),
        proViewId,
      }}
    >
      {({ error, data, refetch }) => {
        if (error) return `Error! ${error.message}`;
        if (!data || !data.solicitation) {
          return <Loader active inline />;
        }

        const solicitations = data.solicitation;

        if (solicitations.length) {
          return (
            <Fragment>
              <Table striped basic="very">
                <Responsive
                  as={Table.Header}
                  minWidth={Responsive.onlyTablet.minWidth}
                >
                  <Table.Row>
                    <Table.HeaderCell width={3}>Coordonnées</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Date</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell width={3}></Table.HeaderCell>
                  </Table.Row>
                </Responsive>
                <Table.Body>
                  {solicitations.map((solicitation) => (
                    <Table.Row key={solicitation.id} verticalAlign="top">
                      <Table.Cell>
                        {solicitation.givenName} {solicitation.familyName}
                        <br />
                        {formatTelephone(solicitation.telephone)}
                        <br />
                        {solicitation.addressLocality}
                        <br />
                        <a
                          href={`mailto:${solicitation.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {solicitation.email}
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateTime(solicitation.dateCreated)}
                      </Table.Cell>
                      <Table.Cell>{solicitation.message}</Table.Cell>
                      <Table.Cell textAlign="center" verticalAlign="middle">
                        {!solicitation.proReview && !solicitation.declinedBy ? (
                          <Fragment>
                            <Responsive
                              minWidth={Responsive.onlyTablet.minWidth}
                            >
                              <Dropdown
                                icon="triangle down"
                                compact
                                button
                                basic
                                className="icon primary"
                                direction="left"
                                text="Actions"
                                color="orange"
                                style={{
                                  width: '130px',
                                  textAlign: 'center',
                                }}
                              >
                                <Dropdown.Menu
                                  style={{
                                    marginTop: '10px',
                                    width: '130px',
                                  }}
                                >
                                  <Dropdown.Item
                                    icon="remove"
                                    text="Décliner"
                                    onClick={() =>
                                      setDeclineModal({
                                        open: true,
                                        solicitation: solicitation,
                                        touched: declineModal.touched,
                                        declineReason: '',
                                      })
                                    }
                                  />
                                  <Dropdown.Item
                                    as="a"
                                    style={{ textDecoration: 'none' }}
                                    href={`mailto:${solicitation.email}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    icon="mail"
                                    text="Répondre"
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                              <Button
                                style={{ padding: '15px' }}
                                compact
                                onClick={() =>
                                  setDeclineModal({
                                    open: true,
                                    solicitation: solicitation,
                                    touched: declineModal.touched,
                                    declineReason: '',
                                  })
                                }
                              >
                                <Icon name="remove" />
                                Décliner
                              </Button>
                              <Button
                                compact
                                as="a"
                                style={{
                                  textDecoration: 'none',
                                  padding: '15px',
                                }}
                                href={`mailto:${solicitation.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Icon name="mail" />
                                Répondre
                              </Button>
                            </Responsive>
                          </Fragment>
                        ) : null}
                        {solicitation.proReview ? (
                          <Fragment>
                            {solicitation.proReview ? (
                              <Label
                                style={{
                                  width: '100px',
                                  textAlign: 'center',
                                  marginRight: '5px',
                                }}
                                size="large"
                              >
                                Avis réalisé
                              </Label>
                            ) : null}
                          </Fragment>
                        ) : null}
                        {!solicitation.proReview && solicitation.declinedBy ? (
                          <Fragment>
                            <p style={{ color: 'red' }}>Décliné par vous :</p>

                            {solicitation.declineReason}
                          </Fragment>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <DeclineModal
                declineModal={declineModal}
                setDeclineModal={setDeclineModal}
                isLoading={isLoading}
                setLoading={setLoading}
                refetch={refetch}
              />
            </Fragment>
          );
        }

        return (
          <Message>
            <p>Aucune demande mail sur la période sélectionée</p>
          </Message>
        );
      }}
    </Query>
  );
};

export default TableContainer;
